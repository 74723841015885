import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom'
import Navbar from './Navbar';
import Footer from './Footer';
import '../css/HyperLeague.css';
import Logo from '../img/Recurso 2@4x.png';
import CONFIG from '../Config.js';

const generateGroupImages = (groupName) => {
  const formattedGroupName = groupName.replace(/ /g, '_');
  const SERVER_BASE_URL = CONFIG.SERVER_BASE_URL;

  const getImage = (path) => {
    return `${SERVER_BASE_URL}/${formattedGroupName}/${path}.png`;
  };

  const getImageArray = (baseName, count) => {
    return Array(count).fill().map((_, i) => getImage(`${baseName}_${i + 1}`));
  };

  return {
    calendarioResultados: getImageArray('calendario_resultado', 6),
    clasificacionRendimientos: getImageArray('clasificacion_rendimientos', 3),
    clasificacionRendimientosDesktop: getImage('clasificacion_rendimientos_desktop'),
    clasificacionRendimientosMobile: getImage('clasificacion_rendimientos_mobile'),
    clasificacionDesktop: getImage('clasificacion_desktop'),
    clasificacionMobile: getImage('clasificacion_mobile'),
    puntosJornadaDesktop: getImage('puntos_jornada_desktop'),
    puntosJornadaMobile: getImage('puntos_jornada_mobile'),
    team: getImageArray('team', 7),
  };
};

const validGroups = [
  'SUB19 Grupo 1',
  'SUB19 Grupo 2',
  'SUB16 Grupo 1',
  'SUB16 Grupo 2',
];

const groupInfo = {
  "SUB19 Grupo 1": "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
  "SUB19 Grupo 2": "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
  "SUB16 Grupo 1": "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
  "SUB16 Grupo 2": "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.",
};


const groupTeams = {
  "SUB19 Grupo 1": ["Powerade","C.E. Poble Sec", "Premier", "Congress", "Singuerlin", "Sant Andreu", "C.E. Europa"],
  "SUB19 Grupo 2": ["Aquarius", "P.B. Anguera", "AT Sant Just", "I. Americano", "FAF Hospitalet", "Joves Sabadell", "Sant Andreu"],
  "SUB16 Grupo 1": ["Powerade","AT Sant Just", "Singuerlin", "Mirasol", "Sant Andreu", "Fredrikstad", "N. Tarragona"],
  "SUB16 Grupo 2": ["Aquarius", "A.P.A. Poble Sec", "Congress", "P.B. Anguera", "Premier", "FAF Hospitalet", "C.E. Europa"],
}

const HyperLeague = () => {
  const { group } = useParams();
  const normalizedGroup = group.replace(/-/g, ' ');
  const [isClausuraDisabled, setIsClausuraDisabled] = useState(true);
  const [activeTab, setActiveTab] = useState('apertura');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [groupImages, setGroupImages] = useState(null);

  useEffect(() => {
    const images = generateGroupImages(normalizedGroup);
    setGroupImages(images);
  }, [normalizedGroup]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!validGroups.includes(normalizedGroup)) {
    return <Navigate to="/404" />;
  }

  return (
    <div className="home-container">
      <Navbar />
      <main className="league-content">
        <section className="hero-league-section">
          <div className="hero-league-content">
            <div className="hero-league-text">
              <h1>{normalizedGroup}</h1>
              {/* <p className="special-font">
              {groupInfo[normalizedGroup] || "Información del grupo."}
              </p> */}
            </div>
          </div>
        </section>

        <div className="tab-navigation content-wrapper">
          <button 
            className={`tab-button ${activeTab === 'apertura' ? 'active' : ''}`} 
            onClick={() => setActiveTab('apertura')}
          >
            APERTURA
          </button>
          <button 
            className={`tab-button ${activeTab === 'meridiano' ? 'active' : ''} ${isClausuraDisabled ? 'disabled' : ''}`} 
            onClick={isClausuraDisabled ? null : () => setActiveTab('meridiano')}
          >
            MERIDIANO
          </button>
          <button 
            className={`tab-button ${activeTab === 'clausura' ? 'active' : ''} ${isClausuraDisabled ? 'disabled' : ''}`} 
            onClick={isClausuraDisabled ? null : () => setActiveTab('clausura')}
          >
            CLAUSURA
          </button>
        </div>

        <section className="tab-content content-wrapper">
          {activeTab === 'apertura' && (
            <div>
              <h1 className="section-title">EQUIPOS</h1>
              <div className="teams-container">
              {groupImages?.team?.map((src, index) => (
                <div key={index} className="team-item">
                  <img src={src} alt={`Equipo ${index + 1}`} className="teams-icon" />
                  <p className="team-label"> {groupTeams[normalizedGroup][index]}</p>
                </div>
              ))}
              </div>
              <div className="logo-position">
                <img src={Logo} alt="Logo" className="logo-image" />
              </div>
              <h1 className="section-title">CALENDARIOS Y RESULTADOS</h1>
              <div className="calendar-grid">
                {groupImages?.calendarioResultados.map((src, index) => (
                  <div key={index} className="calendar-item">
                    {src && (
                      <img 
                        src={src} 
                        alt={`Calendario resultado ${index + 1}`} 
                        className="calendar-image" 
                      />
                    )}
                  </div>
                ))}
              </div>

              {/* <h1 className="section-title">PUNTOS POR JORNADA</h1>
                <div className="league-image-container">
                  {isMobile ? (
                    groupImages?.puntosJornadaMobile && (
                      <img 
                        src={groupImages.puntosJornadaMobile} 
                        alt="puntos por jornada mobile" 
                        className="league-image" 
                      />
                    )
                  ) : (
                    groupImages?.puntosJornadaDesktop && (
                      <img 
                        src={groupImages.puntosJornadaDesktop} 
                        alt="puntos por jornada desktop" 
                        className="league-image" 
                      />
                    )
                  )}
                </div> */}

              <h1 className="section-title">CLASIFICACIÓN</h1>
              <div className="league-image-container">
                {isMobile ? (
                  groupImages?.clasificacionMobile && (
                    <img 
                      src={groupImages.clasificacionMobile} 
                      alt="clasificación" 
                      className="league-image" 
                    />
                  )
                ) : (
                  groupImages?.clasificacionDesktop && (
                    <img 
                      src={groupImages.clasificacionDesktop} 
                      alt="clasificación" 
                      className="league-image" 
                    />
                  )
                )}
              </div>

              <h1 className="section-title">
                CLASIFICACIÓN POR DESEMPEÑOS
                <p className="centered-text">Defensa + Construcción + Ataque</p>
              </h1>
              
              <div className="league-image-container">
                {isMobile ? (
                  groupImages?.clasificacionRendimientosMobile && (
                    <img 
                      src={groupImages.clasificacionRendimientosMobile} 
                      alt="clasificación por desempeños" 
                      className="league-image" 
                    />
                  )
                ) : (
                  groupImages?.clasificacionRendimientosDesktop && (
                    <img 
                      src={groupImages.clasificacionRendimientosDesktop} 
                      alt="clasificación por desempeños" 
                      className="league-image" 
                    />
                  )
                )}
              </div>

              <div className="calendar-grid">
                {groupImages?.clasificacionRendimientos.map((src, index) => (
                  <div key={index} className="calendar-item">
                    {src && (
                      <img 
                        src={src} 
                        alt={`Clasificación desempeños ${index + 1}`} 
                        className="calendar-image" 
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {activeTab === 'meridiano' && <p>Contenido de Meridiano...</p>}
          {activeTab === 'clausura' && <p>Contenido de Clausura...</p>}
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default HyperLeague;